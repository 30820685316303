.App {
  text-align: center;
}

.App-logo {
  height: 20vmin; /* Adjust the height to make the logo smaller */
  width: 20vmin; /* Adjust the width to make the logo smaller */
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.highlight {
  background-color: #0073e6;
  color: white;
  padding: 0.2em 0.4em;
  border-radius: 5px;
}

.continue-button {
  margin-top: 20px;
}
